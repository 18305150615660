import React from 'react'

const RegistrationForm = () => {
  return (
    <div>RegistrationForm</div>
  )
}


export default RegistrationForm;
